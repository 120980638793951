<template>
  <div>
    <h4><span></span>会员统计</h4>
    <ul>
      <li>
        <h5><span class="icon iconfont iconicon_huiyuanrenshu"></span>会员总数</h5>
        <p>{{vipInfo.memberTotalCount}}</p>
      </li>
      <li>
        <h5><span class="icon iconfont iconicon_xinzeng"></span>本月新增</h5>
        <p>
          {{ vipInfo.lastMonthAdded }}
          <i v-if="vipInfo.lastMonthAdded > 0"
            class="icon iconfont iconup"></i>
          <i v-if="vipInfo.lastMonthAdded < 0"
            class="icon iconfont icondown"></i>
        </p>
      </li>
      <li>
        <h5><span class="icon iconfont iconicon_tongbi"></span>同比增加</h5>
        <p>
          {{vipInfo.thisMonthYearOnYearGrowth + '%'}}
          <i v-if="vipInfo.thisMonthYearOnYearGrowth > 0"
            class="icon iconfont iconup"></i>
          <i v-if="vipInfo.thisMonthYearOnYearGrowth < 0"
            class="icon iconfont icondown"></i>
        </p>
      </li>
      <li>
        <h5><span class="icon iconfont iconicon_huanbi"></span>环比增加</h5>
        <p>
          {{vipInfo.thisMonthAnnulusGrowth + '%'}}
          <i v-if="vipInfo.thisMonthAnnulusGrowth > 0"
            class="icon iconfont iconup"></i>
          <i v-if="vipInfo.thisMonthAnnulusGrowth < 0"
            class="icon iconfont icondown"></i>
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
/**
 * 会员统计
 */
  export default {
    props: {
      vipInfo: {
        required: true,
      },
    },
  };
</script>

<style lang="less" scoped>
div{
  h4 {
    font-size: 16px;
    font-weight: 400;
    span {
      display: inline-block;
      width: 4px;
      height: 22px;
      background-color: #02d6dc;
      border-radius: 2px;
      vertical-align: middle;
      margin-right: 12px;
    }
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-left: 12px;
    li {
      width: 40%;
      margin-top: 80px;
      h5 {
        font-size: 20px;
        color: #acacac;
        font-weight: 500;
        margin-bottom: 6px;
        span {
          font-size: 24px;
          margin-right: 5px;
        }
      }
      p {
        position: relative;
        font-size: 26px;
        font-family: "YouSheBiaoTiHei";
        i {
          position: absolute;
          top: 50%;
          right: 20px;
          transform: translateY(-50%);
        }
        .iconup {
          color: #02d6dc;
        }
        .icondown {
          color: #ff8080;
        }
      }
    }
  }
}
</style>
