<template>
  <div class="chart2Warp">
    <div class="lineChart__title">
      <span></span>
      <el-dropdown @command="handleCommand">
        <span class="el-dropdown-link">
          {{option}}<i class="el-icon-caret-bottom el-icon--right"></i>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item v-for="clientType in ClientTypes"
            :key="clientType.key"
            :command="clientType.value">{{clientType.value}}</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    <data-filter style="right: 20px"
      :filterOption="timeRanges"
      @change="changeTimeRange"></data-filter>
    <pie-chart :seriesData="seriesData"
      :width="100"
      :legendData="legendData"
      :subtext="subtext">
    </pie-chart>
  </div>
</template>

<script>
  /**
   * @description 端口占比统计图
   * @author Star
   * @date 2020/08/24
   */
  import * as api from '@/api';
  import PieChart from './PieChart.vue';
  import DataFilter from './DataFilter.vue';

  export default {
    components: {
      PieChart,
      DataFilter,
    },
    data() {
      return {
        subtext: '',
        timeRanges: [
          {
            txt: '本周',
            value: 0,
          },
          {
            txt: '本月',
            value: 1,
          },
          {
            txt: '本年',
            value: 2,
          },
        ],
        option: '浏览次数端口占比',
        timeRange: 0,
        ClientTypes: [
          {
            key: 'pv',
            value: '浏览次数端口占比',
          },
          {
            key: 'uv',
            value: '独立用户端口占比',
          },
        ],
        legendData: [], // 图例数据
        seriesData: [],
      };
    },
    watch: {
      timeRange() {
        this.getVisitClientType();
      },
    },
    methods: {
      changeTimeRange(selected) {
        this.timeRange = selected;
      },
      // 浏览次数端口占比
      async getVisitClientType() {
        const option = this.ClientTypes.find((item) => this.option === item.value)?.key;
        this.subtext = `${option}总数`;
        const result = await api.getVisitClientType({ option, timeRange: this.timeRange.toString() });
        if (!api.error(result)) {
          this.legendData = result.data.map((item) => (item.txt === 'Web' ? 'PC端' : '移动端'));
          this.seriesData = result.data.map((item, index) => {
            if (index === 0) {
              return {
                value: item.amount,
                name: item.txt === 'Web' ? 'PC端' : '移动端',
                itemStyle: { color: 'rgb(2,213,220)' },
              };
            } if (index === 1) {
              return {
                value: item.amount,
                name: item.txt === 'Web' ? 'PC端' : '移动端',
                itemStyle: { color: 'rgb(157,65,248)' },
              };
            }
            return {
              value: item.amount,
              name: item.txt === 'Web' ? 'PC端' : '移动端',
            };
          });
        }
      },
      async getDownloadRate() {
        const result = await api.getDownloadRate({
          timeRange: '2',
        });
        console.log(9999, result);
      },
      handleCommand(command) {
        this.option = command;
        this.getVisitClientType();
      },
    },
    mounted() {
      this.getVisitClientType();
      this.getDownloadRate();
    },
  };
</script>

<style lang="less" scoped>
  .chart2Warp {
    width: 460px;
    position: relative;
    .chart2 {
      height: 400px;
    }
    .lineChart__title {
      cursor: pointer;
      font-size: 14px;
      position: absolute;
      top: 18px;
      left: 16px;
      font-size: 16px;
      padding-left: 10px;
      z-index: 2;
      .el-dropdown-link {
        color: #ffffff;
        font-size: 16px;
      }
      > span:nth-of-type(1) {
        display: inline-block;
        width: 4px;
        height: 22px;
        background-color: #02d6dc;
        border-radius: 2px;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
  }
</style>
