
  /**
   * @author Star
   * @date 2020/08/24
   * @description 页面流量趋势图
   */
  import * as api from '@/api';
  import DataFilter from './DataFilter.vue';
  import LineChart from './LineChart.vue';

  const VisitOption = {
    全站页面流量趋势: 'allpage',
    落地页流量趋势: 'Home',
    首页流量趋势: 'ProductList',
    产品详情页流量趋势: 'ProductDetail',
  };
  export default {
    components: {
      DataFilter,
      LineChart,
    },
    data() {
      return {
        option: '全站页面流量趋势',
        timeRange: 0,
        visitXAxisData: null, // 流量统计x轴数据
        visitSeriesData: null as any, // 流量统计y轴数据
        visitlegendData: null as any, // 流量统计图例数据
        timeRanges: [
          {
            txt: '本周',
            value: 0,
          },
          {
            txt: '本月',
            value: 1,
          },
          {
            txt: '本年',
            value: 2,
          },
        ],
      };
    },
    watch: {
      timeRange() {
        (this as any).getVisit();
      },
    },
    methods: {
      handleCommand(command) {
        (this as any).option = command;
        (this as any).getVisit();
      },
      changeTimeRange(selected) {
        (this as any).timeRange = selected;
      },
      // 流量统计接口
      async getVisit() {
        const response = await api.getVisit({ option: VisitOption[(this as any).option], timeRange: (this as any).timeRange.toString() });
        if (!api.error(response)) {
          console.log(response);
          (this as any).visitXAxisData = response.data.pv.items.map((item) => item.txt);
          (this as any).visitSeriesData = Object.keys(response.data).map((item) => (response.data[item].items.map((i) => i.amount)));
          (this as any).visitlegendData = Object.keys(response.data).map((item) => {
            if (item === 'pv') {
              return '浏览次数(PV)';
            }
            return '独立访客(UV)';
          });
        }
      },
    },
    mounted() {
      (this as any).getVisit();
    },
  };
