<template>
  <div class="globalStatisticsWarp">
    <div class="globalStatistics-top">
      <traffic-statistic-chart></traffic-statistic-chart>
      <Port-statistics-chart></Port-statistics-chart>
    </div>
    <div class="globalStatistics-bottom">
      <div class="globalStatistics-bottom__left">
        <member-statistic :vipInfo="vipInfo"></member-statistic>
      </div>
      <div class="globalStatistics-bottom__right">
        <member-extend-chart :firstRegisteredAt="firstRegisteredAt"></member-extend-chart>
      </div>
    </div>
  </div>
</template>

<script>
  import Vue from 'vue';
  import moment from 'moment';
  import * as api from '@/api';
  import MemberStatistic from './MemberStatistic.vue'; // 会员统计
  import TrafficStatisticChart from './TrafficStatisticChart.vue'; // 流量统计
  import PortStatisticsChart from './PortStatisticChart.vue'; // 端口占比统计
  import MemberExtendChart from './MemberExtendChart.vue'; // 成员趋势统计图
  // import ResizeMinxin from '@/mixins/ResizeMixin';

  export default Vue.extend({
    components: {
      MemberStatistic,
      TrafficStatisticChart,
      PortStatisticsChart,
      MemberExtendChart,
    },
    data() {
      return {
        vipInfo: {
          memberTotalCount: 0,
          lastMonthAdded: 0,
          thisMonthYearOnYearGrowth: 0,
          thisMonthAnnulusGrowth: 0,
          firstRegisteredAt: '',
        },
        firstRegisteredAt: '',
      };
    },
    methods: {
      // 新增会员趋势
      async getVipInfo() {
        const vipInfo = await api.getVipInfo();
        if (!api.error(vipInfo)) {
          this.vipInfo = vipInfo.data;
          this.firstRegisteredAt = moment(vipInfo.data.firstRegisteredAt).format('YYYY-MM');
        }
      },
    },
    mounted() {
      this.getVipInfo();
    },
  });
</script>

<style lang="less">
  .globalStatisticsWarp {
    color: #ffffff;
    min-height: 100%;
    background-image: linear-gradient(90deg, #293944 0%, #29282d 100%);
    .lineChart__title {
      cursor: pointer;
      font-size: 14px;
      position: absolute;
      top: 18px;
      left: 16px;
      font-size: 16px;
      padding-left: 10px;
      z-index: 2;
      .el-dropdown-link {
        color: #ffffff;
        font-size: 16px;
      }
      > span:nth-of-type(1) {
        display: inline-block;
        width: 4px;
        height: 22px;
        background-color: #02d6dc;
        border-radius: 2px;
        vertical-align: middle;
        margin-right: 12px;
      }
    }
    .globalStatistics-top {
      display: flex;
      border-bottom: 1px solid #4a4a4a;
      padding-bottom: 40px;
    }
    .globalStatistics-bottom {
      display: flex;
      .globalStatistics-bottom__left {
        width: 400px;
        padding: 24px;
      }
      .globalStatistics-bottom__right {
        flex: 1;
        position: relative;
        .lineChartContainer {
          .time_select {
            z-index: 1;
            position: absolute;
            right: 20px;
            top: 18px;
            .el-input--mini .el-input__icon {
              line-height: 22px;
            }
            .el-input__inner {
              background: transparent;
              border: 1px solid #acacac;
              border-radius: 20px;
              height: 22px;
              line-height: 22px;
              width: 114px;
            }
          }
        }
      }
    }
  }
</style>
