<template>
  <div ref="lineChart"
    class="lineChart">
  </div>
</template>

<script>
  import echarts from 'echarts';
  import ResizeMinxin from '@/mixins/ResizeMixin.js';

  export default {
    props: {
      xAxisData: {
        // X轴上的数据
        type: Array,
        required: true,
      },
      // y轴上的数据
      seriesData: {
        type: Array,
        required: true,
      },
      // 图例的数据
      legendData: {
        type: Array,
        default: null,
      },
    },
    mixins: [ResizeMinxin],
    data() {
      return {
        echartElm: 'lineChart',
        echart: null,
      };
    },
    watch: {
      seriesData: {
        handler() {
          this.echartInit();
        },
        deep: true,
      },
    },
    methods: {
      echartInit() {
        this.echart = echarts.init(this.$refs.lineChart, 'macarons');
        const areaStyles = [
          {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(2,213,220, 0.3)',
            }, {
              offset: 0.8,
              color: 'rgba(2,213,220, 0)',
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10,
          },
          {
            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
              offset: 0,
              color: 'rgba(157,65,248, 0.3)',
            }, {
              offset: 0.8,
              color: 'rgba(157,65,248, 0)',
            }], false),
            shadowColor: 'rgba(0, 0, 0, 0.1)',
            shadowBlur: 10,
          },
        ];
        const itemStyles = [
          {
            color: 'rgb(2,213,220)',
            borderColor: 'rgba(2,213,220,0.27)',
            borderWidth: 12,
          },
          {
            color: 'rgb(157,65,248)',
            borderColor: 'rgba(157,65,248, 0.2)',
            borderWidth: 12,
          },
        ];
        const { xAxisData } = this;
        const { seriesData } = this;
        const { legendData } = this;
        const series = seriesData.map((item, index) => ({
          name: legendData ? legendData[index] : '',
          type: 'line',
          smooth: true,
          symbol: 'circle',
          symbolSize: 5,
          showSymbol: false,
          lineStyle: {
            width: 2,
          },
          areaStyle: areaStyles[index],
          itemStyle: itemStyles[index],
          data: item,
        }));
        const option1 = {
          tooltip: {
            trigger: 'axis',
          },
          legend: {
            top: 20,
            show: legendData !== null,
            icon: 'circle',
            itemWidth: 8,
            itemHeight: 8,
            itemGap: 13,
            data: legendData,
            right: '4%',
            textStyle: {
              fontSize: 12,
              color: '#F1F1F3',
            },
          },
          grid: {
            top: 100,
            left: '2%',
            right: '2%',
            bottom: '2%',
            containLabel: true,
          },
          xAxis: [{
            type: 'category',
            boundaryGap: false,
            axisLine: {
              lineStyle: {
                color: '#4e4e4e',
              },
            },
            axisLabel: {
              fontSize: 12,
              color: '#acacac',
              padding: [0, 30, 0, 0],
            },
            nameTextStyle: {
              color: 'red',
            },
            data: xAxisData,
          }],
          yAxis: [{
            type: 'value',
            // name: '(%)',
            axisTick: {
              show: false,
            },
            axisLine: {
              lineStyle: {
                color: '#4e4e4e',
              },
            },
            axisLabel: {
              margin: 10,
              fontSize: 12,
              color: '#acacac',
            },
            splitLine: {
              lineStyle: {
                color: '#4e4e4e',
                type: 'dashed',
              },
            },
          }],
          series,
        };
        this.echart.setOption(option1);
      },
    },
    mounted() {
      this.echartInit();
    },
  };
</script>

<style scoped lang="less">
  .lineChart {
    height: 400px;
  }
</style>
