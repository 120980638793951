<template>
  <div class="lineChartContainer">
    <div class="lineChart__title">
      <span></span>
      新增会员趋势
    </div>
    <data-filter style="right: 150px"
      :filterOption="selectedTimeOption2"
      @change="changeTimeRange2"></data-filter>
    <el-select v-model="value"
      size="mini"
      class="time_select"
      placeholder="请选择">
      <el-option v-for="item in options"
        :key="item"
        :label="item"
        :value="item">
      </el-option>
    </el-select>
    <LineChart class="liuliangLine"
      v-if="vipAddTrendXAxisData"
      :seriesData="vipAddTrendSeriesData"
      :xAxisData="vipAddTrendXAxisData"></LineChart>
  </div>
</template>

<script>
  import moment from 'moment';
  import * as api from '@/api';
  import { yymmDate, getYearArr } from '../../utils/tool';
  import DataFilter from './DataFilter.vue';
  import LineChart from './LineChart.vue';

  export default {
    components: {
      LineChart,
      DataFilter,
    },
    props: ['firstRegisteredAt'],
    data() {
      return {
        options: [],
        value: moment(new Date()).format('YYYY-MM'),
        selectedTimeOption2: [
          {
            txt: '按月',
            value: 1,
          },
          {
            txt: '按年',
            value: 2,
          },
        ],
        timeStandard: 1,
        vipAddTrendXAxisData: null,
        vipAddTrendSeriesData: null,
      };
    },
    watch: {
      timeStandard(val) {
        if (val === 1) {
          this.value = moment(new Date()).format('YYYY-MM');
          this.options = yymmDate(moment(this.firstRegisteredAt).format('YYYY-MM'), moment(new Date()).format('YYYY-MM'));
        } else {
          this.value = moment(new Date()).format('YYYY');
          this.options = getYearArr(moment(this.firstRegisteredAt).format('YYYY-MM'), moment(new Date()).format('YYYY-MM')).reverse();
        }
        this.getAddTrend();
      },
      value() {
        this.getAddTrend();
      },
      firstRegisteredAt() {
        this.value = moment(new Date()).format('YYYY-MM');
        this.options = yymmDate(moment(this.firstRegisteredAt).format('YYYY-MM'), moment(new Date()).format('YYYY-MM'));
      },
    },
    methods: {
      async getAddTrend() {
        const vipAddTrend = await api.getAddTrend({
          SelectedTime: this.timeStandard === 2 ? `${this.value}-01` : this.value,
          TimeRange: this.timeStandard.toString(),
        });
        if (!api.error(vipAddTrend)) {
          this.vipAddTrendXAxisData = vipAddTrend.data.memberDatas.map((item) => item[0]);
          this.vipAddTrendSeriesData = [vipAddTrend.data.memberDatas.map((item) => item[1])];
        }
      },
      changeTimeRange2(selected) {
        this.timeStandard = selected;
      },
    },
    mounted() {
      this.getAddTrend();
    },
  };
</script>

<style lang="less" scoped>
  .lineChartContainer {
    flex: 1;
    position: relative;
    > ul {
      position: absolute;
      display: inline-block;
      font-size: 12px;
      border-radius: 22px;
      border: 1px solid #acacac;
      right: 150px;
      top: 18px;
      z-index: 1;
      li {
        display: inline-block;
        padding: 2px 20px;
        color: #acacac;
        cursor: pointer;
      }
      li.active {
        color: #02d6dc;
      }
    }
    .time_select {
      z-index: 1;
      position: absolute;
      right: 20px;
      top: 18px;
      .el-input--mini .el-input__icon {
        line-height: 22px;
      }
      .el-input__inner {
        background: transparent;
        border: 1px solid #acacac;
        border-radius: 20px;
        height: 22px;
        line-height: 22px;
        width: 114px;
      }
    }
  }
</style>
