/**
 * get time array by time range
 * @param st start time eg：'2018-08'
 * @param ed end time eg: '2020-08'
 */
export const yymmDate = function (st, ed) {
  const arr = [] as any;
  const stDate = new Date(st);
  const edDate = new Date(ed);

  const stY = stDate.getFullYear();
  const stM = stDate.getMonth();
  const edY = edDate.getFullYear();
  const edM = edDate.getMonth();
  const len2 = edY - stY;

  for (let i = 0; i < len2 + 1; i++) {
    for (let j = 1; j < 13; j++) {
      if (j >= 10) {
        arr.push(`${stY + i}-${j}`);
      } else {
        arr.push(`${stY + i}-0${j}`);
      }
    }
  }
  arr.splice(0, stM);
  arr.reverse().splice(0, 11 - edM);
  return arr;
};

export const getYearArr = function (start, end) {
  const arr = [] as any;
  const st = new Date(start);
  const ed = new Date(end);

  const stY = st.getFullYear();
  const edY = ed.getFullYear();
  const len = edY - stY;

  if (stY !== edY) {
    for (let j = 0; j < len + 1; j++) {
      arr.push(stY + j);
    }
    return arr;
  }
  arr.push(edY);
  return arr;
};
